<template>
  <v-card class="pa-5">
    <v-card-text
      v-if="error"
      class="mb-0"
    >
      <v-alert
        dense
        outlined
        border="left"
        type="error"
        class="mb-0"
      >
        {{ error.message }}
      </v-alert>
    </v-card-text>
    <v-card-text>
      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :nudge-right="40"
        :return-value.sync="value.time"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px"
      >
        <template #activator="{ on }">
          <v-text-field
            v-model="value.time"
            label="Time To Shutdown The System Every Day"
            outlined
            prepend-icon="mdi-clock"
            readonly
            v-on="on"
          />
        </template>
        <v-time-picker
          v-if="menu"
          v-model="value.time"
          full-width
          :allowed-minutes="allowedMinutes"
          @click:minute="$refs.menu.save(value.time)"
        />
      </v-menu>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'MShutdownForm',
  props: {
    value: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    menu: false,
    error: null,
    allowedMinutes: [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55]
  })
}
</script>
